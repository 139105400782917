import {
  MakerContactCreateParam,
  MakerContactResponse,
  MakerResponse,
  ProductDetailResponse,
  ProductVariationResponse,
} from "@/web-client/api";
import { FC, useCallback, useMemo } from "react";
import FormUnit from "@/components/FormUnit";
import ReactTextareaAutosize from "react-textarea-autosize";
import SingleSelect from "@/components/SingleSelect";
import { FormProvider, useForm } from "react-hook-form";
import useProgress from "@/hooks/useProgress";
import client from "@/utils/api/client";
import ContactKinds from "@/domain/values/ContactKinds";
import ProductVariationBasicInfoBox from "@/features/product_variation/components/ProductVariationBasicInfoBox";
import useAnalytics from "@/hooks/useAnalytics";

type SelectedProductVariationProps = {
  productVariation: ProductVariationResponse;
  changeable: boolean;
  onDeselect: VoidFunction;
};

const SelectedProductVariation: FC<SelectedProductVariationProps> = ({
  productVariation,
  changeable,
  onDeselect,
}): JSX.Element => {
  return (
    <div className="flex items-center gap-16">
      <div className="relative border border-primary p-8 flex justify-between items-center">
        <ProductVariationBasicInfoBox productVariation={productVariation} />
      </div>

      {changeable && (
        <button
          onClick={onDeselect}
          className="text-danger text-sm shrink-0"
          type="button"
        >
          選択を解除
        </button>
      )}
    </div>
  );
};

type FormType = Omit<
  MakerContactCreateParam,
  "product_id" | "product_varation_id"
>;

type MakerContactModalFormContentProps = {
  maker: MakerResponse;
  product?: ProductDetailResponse;
  selectedProductVariation?: ProductVariationResponse;
  onSubmitted: (contact: MakerContactResponse) => void;
  onStartSelect: VoidFunction;
  onDeselect: VoidFunction;
};

const MakerContactModalFormContent: FC<MakerContactModalFormContentProps> = ({
  maker,
  product,
  selectedProductVariation,
  onSubmitted,
  onStartSelect,
  onDeselect,
}) => {
  const progress = useProgress();
  const { event } = useAnalytics();

  const changeableProductVariation = useMemo(() => {
    if (!product) return false;

    return product.variations.length > 1;
  }, [product]);

  const form = useForm<FormType>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const submitHandler = useCallback(
    (data: FormType) => {
      progress(
        async () => {
          const { kind, content } = data;

          const { data: newContact } = await client.makersIdContactsPost({
            id: maker.id,
            makerContactCreateParam: {
              kind,
              content,
              product_id: product?.id,
              product_variation_id: selectedProductVariation?.id,
            },
          });

          event("create_maker_contact", {
            maker_id: maker.id,
            item_id: selectedProductVariation?.id,
          });

          reset();

          onSubmitted(newContact);
        },
        {
          errorMessage: "お問い合わせの送信に失敗しました",
        },
      );
    },
    [
      progress,
      reset,
      onSubmitted,
      product,
      maker,
      selectedProductVariation,
      event,
    ],
  );

  return (
    <div className="relative space-y-32">
      <h1 className="font-bold text-xl laptop:text-3xl text-center">
        メーカーへのお問い合わせ
      </h1>

      <FormProvider {...form}>
        <form className="space-y-32" onSubmit={handleSubmit(submitHandler)}>
          <FormUnit id="maker" label="お問い合わせメーカー">
            <div className="flex items-center gap-8">
              {maker.icon && (
                <img
                  src={maker.icon.urls.small || ""}
                  className="w-64 h-64 rounded-full object-contain border border-primary"
                  alt={`${maker.name}のアイコン`}
                />
              )}
              <p>{maker.name}</p>
            </div>
          </FormUnit>

          {product && (
            <div className="flex flex-col gap-12">
              <p className="font-bold">お問い合わせ製品</p>
              <div className="flex flex-col items-start gap-8">
                {selectedProductVariation ? (
                  <SelectedProductVariation
                    productVariation={selectedProductVariation}
                    changeable={changeableProductVariation}
                    onDeselect={onDeselect}
                  />
                ) : (
                  <>
                    <p className="text-lg">{product.name_primary}</p>
                    <button
                      className="bg-black text-white rounded-sm p-12"
                      onClick={onStartSelect}
                      type="button"
                    >
                      バリエーションを選択
                    </button>
                  </>
                )}
              </div>
            </div>
          )}

          <FormUnit
            id="kind"
            label="お問い合わせ種別"
            error={errors.kind?.message}
            attention="required"
          >
            <SingleSelect
              name="kind"
              options={ContactKinds.map(({ value, label }) => {
                return { value: String(value), label };
              })}
              rules={{ required: "必須選択項目です" }}
            />
          </FormUnit>

          <FormUnit
            id="content"
            label="お問い合わせ内容"
            error={errors.content?.message}
            attention="required"
          >
            <ReactTextareaAutosize
              minRows={6}
              className="w-full"
              {...register("content", { required: "必須入力項目です" })}
            />
          </FormUnit>

          <div className="sticky bottom-0 py-8">
            <button
              className="w-full bg-yellow-500 p-8 rounded-xs"
              type="submit"
            >
              送信する
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default MakerContactModalFormContent;
