import { UploadImageResponse } from "@/web-client/api";
import { useMemo } from "react";
import { useMeasure } from "react-use";
import { UseMeasureRef } from "react-use/lib/useMeasure";

export interface IMasonalizableItem {
  upload_image: UploadImageResponse;
}

type UseMasonoryResponse<T> = {
  items: T[][];
  numberOfColumns: number;
  containerRef: UseMeasureRef<Element>;
  gap: number;
};

const useMasonry = <T extends IMasonalizableItem>(
  masonryItems: T[],
  gap = 16,
  minColumn = 2,
  itemMinWidth = 320,
): UseMasonoryResponse<T> => {
  const [ref, { width }] = useMeasure();

  const numberOfColumns = useMemo<number>(() => {
    // マージンなしに入る数
    const containerableNumberWithoutMargin: number = Math.floor(
      width / itemMinWidth,
    );

    // マージン幅
    const marginWidth: number =
      Math.max(containerableNumberWithoutMargin - 1, 0) * gap;

    // 全体幅
    const totalWidth: number =
      containerableNumberWithoutMargin * itemMinWidth + marginWidth;

    return Math.max(
      totalWidth > width
        ? Math.max(containerableNumberWithoutMargin - 1, 0)
        : containerableNumberWithoutMargin,
      minColumn,
    );
  }, [width, itemMinWidth, gap, minColumn]);

  const organizedItems = useMemo<T[][]>(() => {
    const column = numberOfColumns;
    const items: T[][] = [];
    for (let i = 0; i < column; i++) {
      items.push([]);
    }

    // 各列の高さを保存している配列の初期化
    const heights = [];
    for (let i = 0; i < column; i++) {
      heights.push(0);
    }

    for (let i = 0, length = masonryItems.length; i < length; i++) {
      const masonryItem = masonryItems[i];
      const image = masonryItem.upload_image;

      // 最も低い高さの列を選ぶ
      const column = heights.indexOf(Math.min(...heights));

      // 配列に追加する
      items[column].push(masonryItem);

      // 高さを保存する
      heights[column] += image.height / image.width;
    }

    return items;
  }, [numberOfColumns, masonryItems]);

  return {
    items: organizedItems,
    numberOfColumns,
    containerRef: ref,
    gap,
  };
};
export default useMasonry;
