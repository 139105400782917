import { CSSObjectWithLabel } from "react-select";
import { theme } from "tailwind.config";

export const ReactSelectStyles = {
  control: (provided, state): CSSObjectWithLabel => ({
    ...provided,
    borderRadius: 9999,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: state.isFocused
      ? theme.colors.yellow[500]
      : theme.colors.gray[200],
    backgroundColor: theme.colors.white,
    transition: "background-color linear .2s",
    fontSize: 14,
    boxShadow: "none",
  }),
  valueContainer: (provided): CSSObjectWithLabel => ({
    ...provided,
    padding: "0 16px",
  }),
  indicatorSeparator: (): CSSObjectWithLabel => ({
    display: "none",
  }),
  option: (provided): CSSObjectWithLabel => ({
    ...provided,
    fontSize: 13,
    padding: "6px 8px",
    cursor: "pointer",
  }),
};

export const ReactSelectDefaultStyles = (
  minHeight: number = 48,
  fontSize: number = 16,
  minWidth?: number,
) => {
  return {
    menu: (provided) => ({
      ...provided,
      fontSize: fontSize,
    }),
    indicatorSeparator: () => null,
    dropdownIndicator: (provided) => ({
      ...provided,
      color: theme.colors.gray[950],
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight,
      minWidth,
      fontSize,
      borderColor: state.isFocused
        ? theme.colors.yellow[500]
        : theme.colors.gray[950],
      boxShadow: state.isFocused ? "0 0 0px 4px rgb(255 212 0 / 0.3)" : "none",

      "&:hover": {
        borderColor: theme.colors.yellow[500],
        cursor: "pointer",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "black" : "white",
      color: state.isSelected ? "white" : "black",
      fill: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: theme.colors.gray[200],
        color: "black",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: theme.colors.gray[300],
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.gray[50],
      padding: "0 0 0 8px",
      borderRadius: "4px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "4px 0 4px 0",
      fontSize: "16px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      padding: "0 8px 0",
      ":hover": {
        backgroundColor: theme.colors.gray[100],
      },
    }),
    container: (provided) => ({
      ...provided,
      width: "auto",
    }),
  };
};

export const ReactSelectPlaceholder = "選択なし";
